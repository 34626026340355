import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../../../state/appState';
import {TransactionFilterModel} from '../../../../shared/models/transactionFilter.model';
import {Debounce} from 'lodash-decorators';
import {SetTransactionFilter} from '../../../../state/actions/card.actions';

@Component({
  selector: 'app-transaction-filter',
  templateUrl: './transaction-filter.component.html',
  styleUrls: ['./transaction-filter.component.scss']
})
export class TransactionFilterComponent implements OnInit, OnDestroy {
  @Select(AppState.transactionFilter$) currentFilter$: Observable<TransactionFilterModel>;

  public filterForm: FormGroup;
  public now = new Date();

  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.subscriptions.push(
      this.currentFilter$.subscribe(f => {
        this.filterForm.patchValue(
          f,
          { emitEvent: false, onlySelf: true }
        );
      })
    );

    this.subscriptions.push(
      this.filterForm.get('fromDate').valueChanges.subscribe(() => this.propagateChanges())
    );
    this.subscriptions.push(
      this.filterForm.get('toDate').valueChanges.subscribe(() => this.propagateChanges())
    );
    this.subscriptions.push(
      this.filterForm.get('pos').valueChanges.subscribe(() => this.propagateChangesDebounced())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onPrint(): void {
    window.print();
  }

  @Debounce(1000)
  public propagateChangesDebounced(): void {
    this.propagateChanges();
  }

  public propagateChanges(): void {
    const f: TransactionFilterModel = this.filterForm.getRawValue();
    this.store.dispatch(new SetTransactionFilter(f));
  }

  private initForm(): void {
    this.filterForm = this.fb.group({
      fromDate: new FormControl(null),
      toDate: new FormControl(null),
      pos: new FormControl('')
    });
  }
}
