import {AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import flatpickr from 'flatpickr';
import {German} from 'flatpickr/dist/l10n/de';
import {english} from 'flatpickr/dist/l10n/default';
import {Select} from '@ngxs/store';
import {AppState} from '../../../state/appState';
import {Observable, Subscription} from 'rxjs';
import {LanguageModel} from '../../models/language.model';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  @Select(AppState.currentLanguage$) currentLanguage$: Observable<LanguageModel>;
  @ViewChild('datepicker') inputRef: ElementRef;

  @Input() placeholder = '';
  @Input() disabled = false;
  @Input('maxDate') set setMaxDate(d: Date) {
    this.maxDate = d;
    this.initFlatpickr();
  }
  @Input('minDate') set setMinDate(d: Date) {
    this.minDate = d;
    this.initFlatpickr();
  }
  @Input() useEndOfDay = false;

  public currentValue: Date;

  private subscriptions: Subscription[] = [];
  private language: LanguageModel;
  private maxDate: Date;
  private minDate: Date;

  private onChange: any;
  private onTouched: any;

  constructor(private datePipe: DatePipe) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.currentLanguage$.subscribe(l => {
        this.language = l;
        this.initFlatpickr();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: Date): void {
    this.currentValue = obj ?
      obj
      :
      null;
    if (this.useEndOfDay && this.currentValue != null) {
      this.currentValue.setHours(23, 59, 59, 999);
    }
    this.initFlatpickr();
  }

  clearDate(): void {
    flatpickr(this.inputRef.nativeElement).clear();
    this.onValueChange(null);
  }

  onValueChange(value: Date): void {
    this.currentValue = value ?
      value
      :
      null;
    if (this.useEndOfDay && this.currentValue != null) {
      this.currentValue.setHours(23, 59, 59, 999);
    }
    this.initFlatpickr();
    this.onTouched();
    this.onChange(this.currentValue);
  }

  private initFlatpickr(): void {
    let locale: any = english;
    if (this.language) {
      switch (this.language.key.toLowerCase()) {
        case 'de':
          locale = German;
          break;
      }
    }

    if (this.inputRef) {
      flatpickr(this.inputRef.nativeElement, {
        defaultDate: this.currentValue,
        formatDate: (date) => this.datePipe.transform(date, 'mediumDate', `+${new Date().getTimezoneOffset() / 60}`, this.language.key),
        locale,
        maxDate: this.maxDate,
        minDate: this.minDate,
        mode: 'single',
        onChange: (value) => value ? this.onValueChange(value[0]) : null
      });
    }
  }

}
