import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CardSetupResponseModel} from './cardSetupResponse.model';
import {SetupPinRequestModel} from './setupPinRequest.model';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  constructor(private http: HttpClient) { }

  searchCard(cardId: string): Observable<CardSetupResponseModel> {
    return this.http.get<CardSetupResponseModel>(`/v2/setup/${cardId}`);
  }

  setInitialPin(cardId: string, pin: string): Observable<CardSetupResponseModel> {
    return this.http.post<CardSetupResponseModel>(`/v2/setup/${cardId}`, {
      pin
    } as SetupPinRequestModel);
  }
}
