import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../state/appState';
import {map, take, tap} from 'rxjs/operators';
import {CardModel} from '../models/card.model';
import {RouteLogin} from '../../state/actions/routing.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
  @Select(AppState.currentCard$) currentUser: Observable<CardModel>;

  constructor(private store: Store) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.currentUser.pipe(
      take(1),
      map(u => u != null),
      tap((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          console.log('user not logged in, redirecting to login');
          this.store.dispatch(new RouteLogin());
        }
      })
    );
  }
}
