<div class="row">
  <h3>
    {{ 'account.pin-change.title' | translate }}
  </h3>
</div>
<div class="row disclaimer">
  <div class="caption">
    <small>
      {{ 'account.pin-change.disclaimer' | translate }}
    </small>
  </div>
</div>
<form [formGroup]="pinForm" (ngSubmit)="submitPinChange()">
  <div class="row input current-pin">
    <div class="content">
      <input
        type="password"
        class="input"
        [placeholder]="'account.pin-change.form.current-pin' | translate"
        formControlName="pin"
      >
    </div>
  </div>
  <div class="row input">
    <div class="content">
      <input
        type="password"
        class="input"
        [class.error]="
          pinForm.get('newPin').errors && pinForm.get('newPin').errors.minlength ||
          pinForm.errors && pinForm.errors.notMatching
        "
        [placeholder]="'account.pin-change.form.new-pin' | translate"
        formControlName="newPin"
      >
    </div>
  </div>
  <div class="row input new-pin-repeat">
    <div class="content">
      <input
        type="password"
        class="input"
        [class.error]="
          pinForm.errors && pinForm.errors.notMatching
        "
        [placeholder]="'account.pin-change.form.new-pin-repeat' | translate"
        formControlName="newPinRepeat"
      >
    </div>
  </div>
  <div class="row form-error">
    <div class="caption">
      <ng-container *ngIf="pinForm.get('newPin').errors && pinForm.get('newPin').errors.minlength; else pinValid">
        <small>
          {{  'account.pin-change.form.errors.minlength' | translate }}
        </small>
      </ng-container>
      <ng-template #pinValid>
        <small *ngIf="pinForm.errors">
          {{  'account.pin-change.form.errors.' + (pinForm.errors | keyvalue)[0].key | translate }}
        </small>
      </ng-template>
    </div>
  </div>
  <div class="row controls">
    <button
      type="submit"
      class="btn"
      [disabled]="!pinForm.valid"
    >
      <span>
        {{ 'account.pin-change.controls.save' | translate }}
      </span>
    </button>
  </div>
</form>
