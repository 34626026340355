import {AppStateConfigModel, AppStateModel, TransactionListPaginationModel} from './appState.model';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  SetAccentColor,
  SetConfig, SetGatewayConfig,
  SetLanguage,
  SetScrolledBody,
  SetTheme,
  StartLoading,
  StopLoading,
  SwitchTheme
} from './actions/general.actions';
import {of} from 'rxjs';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {LanguageService} from '../shared/services/language.service';
import {Injectable} from '@angular/core';
import {LanguageModel} from '../shared/models/language.model';
import {ThemingService} from '../shared/services/theming.service';
import {Themes} from '../shared/enum/themes.enum';
import {
  GetCardDetails,
  GetMoreTransactionListItems,
  GetTransactionDetails,
  GetTransactionList,
  Login,
  Logout,
  SetTransactionFilter,
  UpdatePin,
  UserRestore
} from './actions/card.actions';
import {PaycardResponseModel} from '../shared/services/card/paycard.response.model';
import {CardService} from '../shared/services/card/card.service';
import {CardModel} from '../shared/models/card.model';
import {TransactionListItemModel} from '../shared/models/transactionListItem.model';
import {TransactionDetailModel} from '../shared/models/transactionDetail.model';
import {Navigate} from '@ngxs/router-plugin';
import {
  RouteAccount,
  RouteHome,
  RouteImprint,
  RouteLogin, RoutePayment,
  RouteRegister,
  RouteTransactionDetails
} from './actions/routing.actions';
import {TransactionFilterModel} from '../shared/models/transactionFilter.model';
import {TransactionListItemResponseModel} from '../shared/services/card/transactionListItemResponseModel';
import {TransactionDetailResponseModel} from '../shared/services/card/transactionDetail.response.model';
import {GatewayConfigModel} from '../shared/services/gateway-config/gatewayConfig.model';
import {CreateWebcharge} from './actions/webcharge.actions';
import {WebchargeService} from '../shared/services/webcharge/webcharge.service';
import {WebchargeRequestModel} from '../shared/services/webcharge/webchargeRequest.model';

const DEFAULT_FILTER: TransactionFilterModel = {
  pos: '',
  toDate: new Date(),
  fromDate: new Date(1577833200000)
};

@State<AppStateModel>({
  defaults: {
    loading: true,
    isScrolled: false,
    gatewayConfig: null,
    config: {
      accentColor: '#ddd',
      accentFontColor: '#111',
      availableLanguages: [],
      gateway: null,
      maintenance: false,
      lazyLoadingChunkSize: 20,
      showFooter: false,
      showImprint: false,
      showPrivacy: false,
      showTos: false,
      payment: null
    },
    currentLanguage: null,
    currentTheme: Themes.light,
    authString: null,
    currentCard: null,
    transactionDetails: null,
    transactionList: null,
    transactionFilter: DEFAULT_FILTER
  },
  name: 'AppState'
})
@Injectable()
export class AppState {
  private readonly CREDENTIALS_KEY = 'PROFISHOP_CREDENTIALS';

  // region selectors

  @Selector()
  static config$(state: AppStateModel): AppStateConfigModel {
    return state.config;
  }

  @Selector()
  static gatewayConfig$(state: AppStateModel): GatewayConfigModel {
    return state.gatewayConfig;
  }

  @Selector()
  static isScrolled$(state: AppStateModel): boolean {
    return state.isScrolled;
  }

  @Selector()
  static loading$(state: AppStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static currentLanguage$(state: AppStateModel): LanguageModel {
    return state.currentLanguage;
  }

  @Selector()
  static currentCard$(state: AppStateModel): CardModel {
    return state.currentCard;
  }

  @Selector()
  static authString$(state: AppStateModel): string {
    return state.authString;
  }

  @Selector()
  static transactionList$(state: AppStateModel): TransactionListPaginationModel {
    return state.transactionList;
  }

  @Selector()
  static transactionFilter$(state: AppStateModel): TransactionFilterModel {
    return state.transactionFilter;
  }

  @Selector()
  static transactionDetails$(state: AppStateModel): TransactionDetailModel {
    return state.transactionDetails;
  }

  // endregion

  constructor(
    private languageService: LanguageService,
    private themingService: ThemingService,
    private cardService: CardService,
    private webchargeService: WebchargeService
  ) {
  }

  // region general action

  @Action(StartLoading)
  private startLoading(ctx: StateContext<AppStateModel>): any {
    ctx.setState({...ctx.getState(), loading: true});
  }

  @Action(StopLoading)
  private stopLoading(ctx: StateContext<AppStateModel>): any {
    ctx.setState({...ctx.getState(), loading: false});
  }

  @Action(SetLanguage)
  private setLanguage(ctx: StateContext<AppStateModel>, action: SetLanguage): any {
    return this.languageService.setLanguage(action.language.key).pipe(
      tap(() => {
        ctx.setState({
          ...ctx.getState(),
          currentLanguage: action.language
        });
      })
    );
  }

  @Action(SetScrolledBody)
  private setScrolledBody(ctx: StateContext<AppStateModel>, action: SetScrolledBody): any {
    ctx.setState({
      ...ctx.getState(),
      isScrolled: action.isScrolled
    });
  }

  @Action(SetGatewayConfig)
  private setGatewayConfig(ctx: StateContext<AppStateModel>, action: SetGatewayConfig): any {
    return ctx.setState({
      ...ctx.getState(),
      gatewayConfig: action.config
    });
  }

  @Action(SetConfig)
  private setConfig(ctx: StateContext<AppStateModel>, action: SetConfig): any {
    const config = action.config;

    const setLanguageObservable = !ctx.getState().currentLanguage && config.availableLanguages.length > 0 ?
      ctx.dispatch(new SetLanguage(config.availableLanguages[0])) :
      of();

    return setLanguageObservable.pipe(
      tap(() => ctx.dispatch(new SetAccentColor(config.accentColor, config.accentFontColor))),
      tap(() => {
        ctx.setState(
          {
            ...ctx.getState(),
            config
          }
        );
      })
    );
  }

  @Action(SetAccentColor)
  private setAccentColor(ctx: StateContext<AppStateModel>, action: SetAccentColor): any {
    this.themingService.setAccentColor(action.bgColor, action.fontColor);
  }

  @Action(SwitchTheme)
  private switchTheme(ctx: StateContext<AppStateModel>, action: SwitchTheme): any {
    const newTheme = ctx.getState().currentTheme === Themes.light ? Themes.dark : Themes.light;
    ctx.dispatch(new SetTheme(newTheme));
  }

  @Action(SetTheme)
  private setTheme(ctx: StateContext<AppStateModel>, action: SetTheme): any {
    this.themingService.setTheme(action.theme);
    ctx.setState({
      ...ctx.getState(),
      currentTheme: action.theme
    });
  }

  // endregion

  // region user

  @Action(UserRestore)
  private userRestore(ctx: StateContext<AppStateModel>): any {
    const credentials = this.getCredentials();
    if (credentials) {
      return ctx.dispatch(new Login(credentials.cardId, credentials.pin)).pipe(
        catchError(() => {
          return ctx.dispatch(new Logout());
        })
      );
    } else {
      return ctx.dispatch(new StopLoading());
    }
  }

  @Action(Login)
  private login(ctx: StateContext<AppStateModel>, action: Login): any {
    return ctx.dispatch(new StartLoading()).pipe(
      tap(() => {
        ctx.setState({
          ...ctx.getState(),
          authString: btoa(unescape(encodeURIComponent(`${action.card}:${action.pin}`)))
        });
        this.saveCredentials(action.card, action.pin);
      }),
      mergeMap(() => ctx.dispatch(new GetCardDetails())),
      mergeMap(() => ctx.dispatch(new GetTransactionList())),
      mergeMap(() => ctx.dispatch(new StopLoading())),
      catchError((err) => {
        ctx.setState({
          ...ctx.getState(),
          authString: null,
        });
        ctx.dispatch(new StopLoading());
        throw err;
      })
    );
  }

  @Action(Logout)
  private logout(ctx: StateContext<AppStateModel>, action: Logout): any {
    this.deleteCredentials();

    ctx.setState({
      ...ctx.getState(),
      authString: null,
      currentCard: null,
      transactionDetails: null,
      transactionList: null,
      transactionFilter: DEFAULT_FILTER
    });
    return ctx.dispatch(new RouteLogin()).pipe(
      mergeMap(() => ctx.dispatch(new StopLoading()))
    );
  }

  @Action(GetCardDetails)
  private getCardDetails(ctx: StateContext<AppStateModel>, action: GetCardDetails): any {
    return ctx.dispatch(new StartLoading()).pipe(
      mergeMap(() => this.cardService.getPaycardDetails()),
      tap((response: PaycardResponseModel) => {
        ctx.setState({
          ...ctx.getState(),
          currentCard: {
            accessId: response.accessId,
            active: response.active,
            balance: response.balance,
            balance_card: response.balance_card,
            balance_webcharge: response.balance_webcharge,
            currency: response.currency,
            deleted: response.deleted,
            edition: response.edition,
            cardId: response.paycardId,
            monthlyTopupRemaining: response.monthlyTopupRemaining,
            chargeFrom: response.chargeFrom,
            chargeTo: response.chargeTo,
            maxCardLimit: response.maxCardLimit,
            paymentFrom: response.paymentFrom,
            paymentTo: response.paymentTo,
            webChargeAllowed: response.webChargeAllowed,
            issuerImage: response.issuerImage
          }
        });
      })
    );
  }

  @Action(GetTransactionList)
  private getTransactions(ctx: StateContext<AppStateModel>): any {
    return this.cardService.getTransactions(
      0,
      ctx.getState().config.lazyLoadingChunkSize,
      ctx.getState().transactionFilter
    ).pipe(
      tap(transactions => {
        ctx.setState({
          ...ctx.getState(),
          transactionList: {
            page: transactions.number,
            size: transactions.size,
            isLast: transactions.last,
            items: transactions.content.map(t => this.mapExtTransactionListItemToTransactionListItem(t))
          }
        });
      })
    );
  }

  @Action(GetMoreTransactionListItems)
  private getMoreTransactions(ctx: StateContext<AppStateModel>): any {
    const state = ctx.getState();
    const {page, size, isLast} = ctx.getState().transactionList ?
      state.transactionList
      :
      {page: -1, size: state.config.lazyLoadingChunkSize, isLast: false};

    if (isLast) {
      return of();
    } else {
      return this.cardService.getTransactions(page + 1, size, ctx.getState().transactionFilter).pipe(
        tap(transactions => {
          ctx.setState({
            ...state,
            transactionList: {
              page: transactions.number,
              size: transactions.size,
              isLast: transactions.last,
              items: [
                ...(
                  state.transactionList && state.transactionList.items ?
                    state.transactionList.items
                    :
                    []
                ),
                ...transactions.content.map(t => this.mapExtTransactionListItemToTransactionListItem(t))
              ]
            }
          });
        })
      );
    }
  }

  @Action(SetTransactionFilter)
  private setTransactionFilter(ctx: StateContext<AppStateModel>, action: SetTransactionFilter): any {
    ctx.setState({
      ...ctx.getState(),
      transactionFilter: action.filter
    });
    return ctx.dispatch(new StartLoading()).pipe(
      mergeMap(() => ctx.dispatch(new GetTransactionList())),
      mergeMap(() => ctx.dispatch(new StopLoading())),
    );
  }

  @Action(GetTransactionDetails)
  private getTransactionDetails(ctx: StateContext<AppStateModel>, action: GetTransactionDetails): any {
    if (action.transactionId == null) {
      // clear transaction details
      ctx.setState({
        ...ctx.getState(),
        transactionDetails: null
      });
      return of();
    }

    if (ctx.getState().transactionDetails && ctx.getState().transactionDetails.saleId === action.transactionId) {
      // do nothing, as we already have the right transaction inside the state
      return of();
    }

    return this.cardService.getTransactionDetail(action.transactionId).pipe(
      tap(t => {
        ctx.setState({
          ...ctx.getState(),
          transactionDetails: this.mapExtTransactionDetailToTransactionDetail(t)
        });
      })
    );
  }

  @Action(UpdatePin)
  private updatePin(ctx: StateContext<AppStateModel>, action: UpdatePin): any {
    const state = ctx.getState();
    const cardId = state.currentCard.cardId;
    const api = state.config.gateway;

    return ctx.dispatch(new StartLoading()).pipe(
      mergeMap(() => this.cardService.changePin(cardId, action.oldPin, action.newPin, api)),
      mergeMap(() => ctx.dispatch(new Logout()))
    );
  }

  // endregion

  // region webcharge

  @Action(CreateWebcharge)
  private createWebcharge(ctx: StateContext<AppStateModel>, action: CreateWebcharge): any {
    return ctx.dispatch(new StartLoading()).pipe(
      mergeMap(() => {
        const request: WebchargeRequestModel = {
          amount: action.amount,
          paymentProvider: action.paymentProvider,
          currency: ctx.getState().config?.payment?.currency ? ctx.getState().config.payment.currency : 'EUR',
          callbackSuccessURL: `${window.location.protocol}//${window.location.host}/payment/success`,
          callbackCancelURL: `${window.location.protocol}//${window.location.host}/payment/cancel`,
          callbackErrorURL: `${window.location.protocol}//${window.location.host}/payment/error`,
        };
        return this.webchargeService.createWebcharge(request);
      }),
      tap((response) => {
        window.location.href = response.approvalURL;
      }),
      catchError((error) => {
        ctx.dispatch(new StopLoading());
        throw error;
      })
    );
  }

  // endregion

  // region routing

  @Action(RouteHome)
  private routeHome(ctx: StateContext<AppStateModel>): any {
    return ctx.dispatch(new Navigate(['']));
  }

  @Action(RouteLogin)
  private routeLogin(ctx: StateContext<AppStateModel>, action: RouteLogin): any {
    return ctx.dispatch(new Navigate(
      action.cardId ?
        ['login', action.cardId]
        :
        ['login']
    ));
  }

  @Action(RouteRegister)
  private routeRegister(ctx: StateContext<AppStateModel>, action: RouteRegister): any {
    return ctx.dispatch(new Navigate(['register', action.cardId]));
  }

  @Action(RouteAccount)
  private routeAccount(ctx: StateContext<AppStateModel>): any {
    return ctx.dispatch(new Navigate(['account']));
  }

  @Action(RoutePayment)
  private routePayment(ctx: StateContext<AppStateModel>): any {
    return ctx.dispatch(new Navigate(['payment']));
  }

  @Action(RouteImprint)
  private routeImprint(ctx: StateContext<AppStateModel>, action: RouteImprint): any {
    return ctx.dispatch(new Navigate(['imprint'], {fragment: action.anchor}));
  }

  @Action(RouteTransactionDetails)
  private routeTransactionDetails(ctx: StateContext<AppStateModel>, action: RouteTransactionDetails): any {
    return ctx.dispatch(new Navigate(['transaction', action.saleId]));
  }

  // endregion

  // region helper

  private saveCredentials(cardId: string, pin: string): void {
    window.localStorage.setItem(this.CREDENTIALS_KEY, JSON.stringify({cardId, pin}));
  }

  private deleteCredentials(): void {
    window.localStorage.removeItem(this.CREDENTIALS_KEY);
  }

  private getCredentials(): { cardId: string, pin: string } {
    const credentials = window.localStorage.getItem(this.CREDENTIALS_KEY);
    if (credentials) {
      try {
        return JSON.parse(credentials);
      } catch {
      }
    }
    return null;
  }

  private mapExtTransactionListItemToTransactionListItem(t: TransactionListItemResponseModel): TransactionListItemModel {
    return {
      amount: t.amount,
      currency: t.currency,
      saleId: t.saleID,
      date: new Date(t.timestamp),
      type: t.type,
      pos: t.pos,
      reference: t.reference,
      transactionId: t.transactionId
    } as TransactionListItemModel;
  }

  private mapExtTransactionDetailToTransactionDetail(t: TransactionDetailResponseModel): TransactionDetailModel {
    return {
      editedAt: t.editedAt,
      printoutPreview: t.printoutPreview,
      amount: t.amount,
      currency: t.currency,
      saleId: t.saleID,
      date: new Date(t.timestamp),
      type: t.type,
      pos: t.pos,
      reference: t.reference,
      transactionId: t.transactionId
    } as TransactionDetailModel;
  }

  // endregion
}
