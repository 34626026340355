import {Component, Input} from '@angular/core';
import {TransactionListItemModel} from '../../../../shared/models/transactionListItem.model';
import {Select} from '@ngxs/store';
import {AppState} from '../../../../state/appState';
import {Observable} from 'rxjs';
import {LanguageModel} from '../../../../shared/models/language.model';
import {TransactionType} from 'src/app/shared/enum/transactionType.enum';

@Component({
  selector: 'app-transaction-list-item',
  templateUrl: './transaction-list-item.component.html',
  styleUrls: ['./transaction-list-item.component.scss']
})
export class TransactionListItemComponent {
  @Input() transactionListItem: TransactionListItemModel;
  @Select(AppState.currentLanguage$) currentLanguage$: Observable<LanguageModel>;

  public TransactionType = TransactionType;

  constructor() { }

}
