<app-login-register-wrapper>
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form" *ngIf="loginForm">
    <img
      class="emblem"
      src="/assets/img/img-login.png"
      alt="city-emblem"
    >
    <div class="input-row">
      <input
        type="text"
        formControlName="card"
        class="input"
        name="card-number"
        id="card-number"
        [class.error]="loginForm.errors"
        [placeholder]="'login.form.placeholder.card' | translate"
      >
    </div>
    <div class="input-row with-caption" *ngIf="validCard">
      <input
        type="password"
        formControlName="pin"
        class="input"
        [class.error]="loginForm.errors"
        [placeholder]="'login.form.placeholder.pin' | translate"
      >
      <small (click)="onResetForm()">
        {{ 'login.form.placeholder.not-your-card' | translate }}
      </small>
    </div>

    <div class="error-description">
      <ng-container *ngIf="loginForm.errors">
        {{  'login.form.errors.' + (loginForm.errors | keyvalue)[0].key | translate }}
      </ng-container>
    </div>

    <div class="input-row">
      <button
        type="submit"
        class="btn"
        [disabled]="!loginForm.valid"
        *ngIf="validCard; else cardNotChecked"
      >
          <span>
            {{ 'login.form.buttons.submit' | translate }}
          </span>
      </button>

      <ng-template #cardNotChecked>
        <button
          type="submit"
          class="btn"
          [disabled]="!loginForm.get('card').valid"
          *ngIf="!validCard"
        >
          <span>
            {{ 'login.form.buttons.check-card' | translate }}
          </span>
        </button>
      </ng-template>
    </div>
  </form>
</app-login-register-wrapper>
