import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../state/appState';
import {Observable, Subscription} from 'rxjs';
import {AppStateConfigModel} from '../../state/appState.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GatewayConfigModel} from '../../shared/services/gateway-config/gatewayConfig.model';
import {CreateWebcharge} from '../../state/actions/webcharge.actions';
import {StopLoading} from '../../state/actions/general.actions';
import {RouteHome} from '../../state/actions/routing.actions';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {CardModel} from '../../shared/models/card.model';
import {mergeMap} from 'rxjs/operators';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
  @Select(AppState.config$) config$: Observable<AppStateConfigModel>;
  @Select(AppState.currentCard$) currentCard$: Observable<CardModel>;
  @Select(AppState.gatewayConfig$) gatewayConfig$: Observable<GatewayConfigModel>;

  public paymentForm: FormGroup;
  public config: AppStateConfigModel;
  public currentCard: CardModel;

  public maxTopup: number = null;
  public showRestTopupButton = false;
  public webchargeAvailable = true;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.config$.pipe(
        mergeMap(cfg => {
          this.config = cfg;
          this.initForm();
          return this.currentCard$;
        })
      ).subscribe((card) => {
        this.currentCard = card;

        if (card.monthlyTopupRemaining != null) {
          if (card.maxCardLimit != null) {
            this.maxTopup = Math.min(card.monthlyTopupRemaining, (card.maxCardLimit - card.balance));
          } else {
            this.maxTopup = card.monthlyTopupRemaining;
          }
        } else {
          if (card.maxCardLimit != null) {
            this.maxTopup = card.maxCardLimit - card.balance;
          }
        }

        if (this.maxTopup != null && this.maxTopup > 0) {
          this.showRestTopupButton = this.maxTopup < Math.min(...this.config.payment.presetAmounts);
        }

        const now = new Date();
        if (
          !card.webChargeAllowed ||
          (card.chargeFrom && now.getTime() < card.chargeFrom.getTime()) ||
          (card.chargeTo && now.getTime() > card.chargeTo.getTime()) ||
          (this.maxTopup != null && this.maxTopup === 0)
        ) {
          this.webchargeAvailable = false;
        }

        this.store.dispatch(new StopLoading());
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onGoHome(): void {
    this.store.dispatch(new RouteHome());
  }

  public setAmount(amount: number): void {
    this.paymentForm.patchValue({
      amount
    });
  }

  public setPaymentProvider(paymentProvider: string): void {
    this.paymentForm.patchValue({
      paymentProvider
    });
    this.submitForm();
  }

  public submitForm(): void {
    const { amount, paymentProvider} = this.paymentForm.getRawValue();
    this.store.dispatch(new CreateWebcharge(amount, paymentProvider)).subscribe(
      () => {},
      (error) => {
        console.log(error);

        const errorCode = error.error ? error.error.message : null;
        let errorMessage = this.translate.instant(`serverErrors.${errorCode}`);
        if (!errorMessage) {
          errorMessage = this.translate.instant('serverErrors.unknown');
        }
        this.toastr.error(errorMessage);
      }
      );
  }

  private initForm(): void {
    this.paymentForm = this.fb.group({
      amount: new FormControl(
        null,
        [
          Validators.required,
          Validators.min(this.config.payment.minAmount),
          Validators.max(this.config.payment.maxAmount)
        ]
      ),
      paymentProvider: new FormControl('', Validators.required)
    });
  }

}
