<form [formGroup]="filterForm">
  <div class="datepicker">
    <app-datepicker
      formControlName="fromDate"
      [placeholder]="'home.transaction-list.filter.placeholder.fromDate' | translate"
      [maxDate]="filterForm.get('toDate').value"
    >
    </app-datepicker>

    <app-datepicker
      formControlName="toDate"
      [placeholder]="'home.transaction-list.filter.placeholder.toDate' | translate"
      [minDate]="filterForm.get('fromDate').value"
      [useEndOfDay]="true"
    >
    </app-datepicker>
  </div>

  <div class="filter">
    <input
      type="text"
      formControlName="pos"
      [placeholder]="'home.transaction-list.filter.placeholder.pos' | translate"
      class="input"
    >
    <button
      class="btn print"
      type="button"
      [title]="'home.transaction-list.filter.placeholder.print' | translate"
      (click)="onPrint()"
    >
      <i class="fas fa-print"></i>
    </button>
  </div>
</form>
