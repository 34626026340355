<div class="content-wrap">
  <div class="row">
    <h2 class="title">
      {{ 'account.title' | translate }}
    </h2>
  </div>
  <div
    *ngIf="currentCard$ | async as card"
    class="row"
  >
    <app-account-card
      class="card"
      [card]="card"
    ></app-account-card>
    <app-account-change-pin
      class="pin"
    ></app-account-change-pin>
  </div>
  &nbsp;
</div>
