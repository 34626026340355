import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../state/appState';
import {Observable, of, Subscription} from 'rxjs';
import {CardModel} from '../../shared/models/card.model';
import {catchError, distinctUntilChanged, mergeMap} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Login} from '../../state/actions/card.actions';
import {RouteHome, RouteRegister} from '../../state/actions/routing.actions';
import {SetupService} from '../../shared/services/setup/setup.service';
import {CardStatusEnum} from '../../shared/enum/cardStatus.enum';
import {SetupErrorCodeEnum} from '../../shared/services/setup/setupErrorCode.enum';
import {StartLoading, StopLoading} from '../../state/actions/general.actions';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Select(AppState.currentCard$) currentCard$: Observable<CardModel>;
  private subs: Subscription[] = [];

  public loginForm: FormGroup;
  public validCard = false;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private setupService: SetupService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(new StartLoading());
    this.subs.push(
      this.activatedRoute.params.subscribe(p => {
        if (p.cardId) {
          this.initForm(p.cardId);
          this.onCheckCard();
        } else {
          this.initForm('');
          this.store.dispatch(new StopLoading());
        }
      })
    );
    this.subs.push(
      this.currentCard$.pipe(distinctUntilChanged()).subscribe((p) => {
        if (p != null) {
          this.store.dispatch(new RouteHome());
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  initForm(cardId: string): void {
    this.loginForm = this.fb.group({
      card: new FormControl({
        value: cardId,
        disabled: this.validCard
      }, Validators.required),
      pin: new FormControl('', Validators.required)
    });
  }

  onCheckCard(): void {
    const {card} = this.loginForm.getRawValue();
    this.store.dispatch(new StartLoading()).pipe(
      mergeMap(() => this.setupService.searchCard(card)),
      mergeMap((cardResponse) => {
        switch (cardResponse.status) {
          case CardStatusEnum.INIT:
            return this.store.dispatch(new RouteRegister(card)).pipe(
              mergeMap(() => this.store.dispatch(new StopLoading()))
            );
            break;
          case CardStatusEnum.VALID:
            this.validCard = true;
            this.loginForm.get('card').disable();
            break;
          default:
            this.loginForm.setErrors({notFound: true});
        }
        return this.store.dispatch(new StopLoading());
      }),
      catchError((error) => {
        switch (error.error.message as SetupErrorCodeEnum) {
          case SetupErrorCodeEnum.PAYCARD_NOT_FOUND_AT_PROFIPAY:
            this.loginForm.setErrors({notFound: true});
            break;
          default:
            this.loginForm.setErrors({unknownError: true});
        }
        return this.store.dispatch(new StopLoading());
      })
    ).subscribe();
  }

  onLoginCard(): void {
    const {card, pin} = this.loginForm.getRawValue();
    this.store.dispatch(new Login(card.toString().trim(), pin)).subscribe(
      () => {
      },
      () => {
        this.loginForm.get('pin').reset();
        this.loginForm.setErrors({loginFailed: true});
      },
    );
  }

  onSubmit(): void {
    this.validCard ?
      this.onLoginCard()
      :
      this.onCheckCard();
  }

  onResetForm(): void {
    this.validCard = false;
    this.loginForm.get('card').enable();
    this.initForm('');
  }

}
