import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {RouteHome} from '../../state/actions/routing.actions';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../state/appState';
import {AppStateConfigModel} from '../../state/appState.model';
import {GatewayConfigModel} from '../services/gateway-config/gatewayConfig.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentEnabledGuard implements CanActivate {
  @Select(AppState.config$) config$: Observable<AppStateConfigModel>;
  @Select(AppState.gatewayConfig$) gatewayConfig$: Observable<GatewayConfigModel>;

  constructor(private store: Store) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return combineLatest([this.config$, this.gatewayConfig$]).pipe(
      mergeMap((resultArray) => {
        const config = resultArray[0];
        const gatewayConfig = resultArray[1];

        const paymentEnabled =
          config && config.payment && config.payment.enabled &&
          gatewayConfig && gatewayConfig.payment && gatewayConfig.payment.enabled && gatewayConfig.webcharge.enabled;

        if (!paymentEnabled) {
          this.store.dispatch(new RouteHome());
        }

        return of(paymentEnabled);
      })
    );
  }

}
