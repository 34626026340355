<ng-container *ngIf="card">
  <h2 class="balance-title">
    {{ 'home.card.balance' | translate }}
  </h2>
  <div class="balance-value">
    <ng-container *ngIf="currentLanguage$ | async as language">
      {{ card.balance/100 | currency:card.currency:'symbol':'':language.key }}
    </ng-container>
  </div>
  <div>
    <small class="edition">
      {{ card.cardId }}
    </small>
  </div>
</ng-container>
