<ng-container *ngIf="currentCard$ | async as card">
  <div class="content-wrap">
    <h2>
      {{ 'payment.title' | translate }}
    </h2>

    <div class="row" *ngIf="card.monthlyTopupRemaining != null">
      <a routerLink="/account">
        <h3 class="amount-left topup-hint" *ngIf="webchargeAvailable">
          {{ 'payment.remaining-amount' | translate: {amount: maxTopup / 100 | currency:'EUR'} }}
        </h3>
      </a>
      <a routerLink="/account">
        <h3 class="amount-left topup-hint" *ngIf="!webchargeAvailable">
          {{ 'payment.webcharge-not-available' | translate }}
        </h3>
      </a>
    </div>
    <form
      [formGroup]="paymentForm"
      (ngSubmit)="submitForm()"
      *ngIf="webchargeAvailable"
    >
      <div class="row amount">
        <h3>
          1. {{ 'payment.choose-amount' | translate }}
        </h3>
        <div class="controls">
          <button
            (click)="setAmount(this.maxTopup)"
            [class.active]="this.maxTopup === paymentForm.get('amount').value"
            class="btn"
            type="button"
            *ngIf="showRestTopupButton"
          >
            {{ this.maxTopup / 100 | currency:config.payment.currency }}
          </button>
          <button
            *ngFor="let amount of config.payment.presetAmounts"
            (click)="setAmount(amount)"
            [class.active]="amount === paymentForm.get('amount').value"
            class="btn"
            type="button"
            [disabled]="amount > maxTopup"
          >
            {{ amount / 100 | currency:config.payment.currency }}
          </button>
        </div>
      </div>

      <ng-container *ngIf="gatewayConfig$ | async as gatewayConfig">
        <ng-container *ngIf="paymentForm.get('amount').value">
          <div class="row payment-provider">
            <h3>
              2. {{ 'payment.payment-provider' | translate }}
            </h3>
            <div class="controls">
              <button
                *ngFor="let paymentProvider of gatewayConfig.payment.paymentMethods"
                (click)="setPaymentProvider(paymentProvider)"
                [class.active]="paymentProvider === paymentForm.get('paymentProvider').value"
                class="btn"
                type="button"
              >
                {{ ('payment.provider.' + paymentProvider) | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>
</ng-container>
