import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Store} from '@ngxs/store';
import {RouteHome} from '../../state/actions/routing.actions';
import {StopLoading} from '../../state/actions/general.actions';

@Component({
  selector: 'app-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss']
})
export class PaymentResultComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public result;

  constructor(private activatedRoute: ActivatedRoute, private store: Store) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        this.result = params.result.toString().toLowerCase();
        if (
          this.result !== 'success' &&
          this.result !== 'error' &&
          this.result !== 'cancel'
        ) {
          this.onGoHome();
        } else {
          this.store.dispatch(new StopLoading());
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onGoHome(): void {
    this.store.dispatch(new RouteHome());
  }
}
