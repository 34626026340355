import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppState} from '../../state/appState';
import {Select, Store} from '@ngxs/store';
import {mergeMap, take} from 'rxjs/operators';
import {AppStateConfigModel} from '../../state/appState.model';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  @Select(AppState.config$) config$: Observable<AppStateConfigModel>;

  constructor(private store: Store) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.config$.pipe(
      take(1),
      mergeMap((config) => {
        if (config && config.gateway) {
          request = request.clone({
            url: `${config.gateway}${request.url}`
          });
        }
        return next.handle(request);
      })
    );
  }
}
