import {Component} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {RouteImprint} from '../../../state/actions/routing.actions';
import {AppState} from '../../../state/appState';
import {Observable} from 'rxjs';
import {AppStateConfigModel} from '../../../state/appState.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Select(AppState.config$) config$: Observable<AppStateConfigModel>;

  constructor(private store: Store) { }

  onShowImprint(): void {
    this.store.dispatch(new RouteImprint('imprint'));
  }

  onShowTos(): void {
    this.store.dispatch(new RouteImprint('tos'));
  }

  onShowPrivacy(): void {
    this.store.dispatch(new RouteImprint('privacy'));
  }
}
