import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private translate: TranslateService) {
  }

  setLanguage(languageKey: string): Observable<any> {
    return this.translate.use(languageKey);
  }
}
