import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WebchargeRequestModel} from './webchargeRequest.model';
import {Observable} from 'rxjs';
import {WebchargeResponseModel} from './webchargeResponse.model';

@Injectable({
  providedIn: 'root'
})
export class WebchargeService {

  constructor(private httpClient: HttpClient) { }

  createWebcharge(request: WebchargeRequestModel): Observable<WebchargeResponseModel> {
    return this.httpClient.post<WebchargeResponseModel>(`/v2/webcharge`, request);
  }
}
