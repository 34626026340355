<div class="content-wrap">
  <h2>
    {{ 'payment-result.' + result + '.title' | translate }}
  </h2>
  <div class="row">
    <div class="description">
      {{ 'payment-result.' + result + '.description' | translate }}
    </div>
  </div>
  <div class="row">
    <div class="controls">
      <button
        class="btn"
        (click)="onGoHome()"
      >
        {{ 'payment-result.' + result + '.confirm' | translate }}
      </button>
    </div>
  </div>
</div>
