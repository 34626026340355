import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HomeComponent} from './routes/home/home.component';
import {TransactionDetailsComponent} from './routes/transaction-details/transaction-details.component';
import {LoginComponent} from './routes/login/login.component';
import {NgxsModule, Store} from '@ngxs/store';
import {AppState} from './state/appState';
import {environment} from '../environments/environment';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {ConfigService} from './shared/services/config.service';
import {SetConfig, SetGatewayConfig, SetTheme} from './state/actions/general.actions';
import {mergeMap} from 'rxjs/operators';
import {LoadingComponent} from './shared/components/loading/loading.component';
import {Themes} from './shared/enum/themes.enum';
import {HeaderComponent} from './shared/components/header/header.component';
import {FooterComponent} from './shared/components/footer/footer.component';
import {AuthInterceptor} from './shared/interceptors/auth.interceptor';
import {ApiInterceptor} from './shared/interceptors/api.interceptor';
import {ReactiveFormsModule} from '@angular/forms';
import {UserRestore} from './state/actions/card.actions';
import {TransactionListComponent} from './routes/home/transaction-list/transaction-list.component';
import {CardInfoComponent} from './routes/home/card-info/card-info.component';
import {CardControlsComponent} from './routes/home/card-controls/card-controls.component';
import {PartnersComponent} from './routes/home/partners/partners.component';
import {
  TransactionListItemComponent
} from './routes/home/transaction-list/transaction-list-item/transaction-list-item.component';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeDE from '@angular/common/locales/de';
import {AccountComponent} from './routes/account/account.component';
import {AccountCardComponent} from './routes/account/account-card/account-card.component';
import {AccountChangePinComponent} from './routes/account/account-change-pin/account-change-pin.component';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {
  TransactionDetailsOverviewComponent
} from './routes/transaction-details/transaction-details-overview/transaction-details-overview.component';
import {
  TransactionDetailsReceiptComponent
} from './routes/transaction-details/transaction-details-receipt/transaction-details-receipt.component';
import {
  TransactionFilterComponent
} from './routes/home/transaction-list/transaction-filter/transaction-filter.component';
import {DatepickerComponent} from './shared/components/datepicker/datepicker.component';
import {ImprintComponent} from './routes/imprint/imprint.component';
import {RegisterComponent} from './routes/register/register.component';
import {
  LoginRegisterWrapperComponent
} from './shared/components/login-register-wrapper/login-register-wrapper.component';
import {MaintenanceComponent} from './shared/components/maintenance/maintenance.component';
import {GatewayConfigService} from './shared/services/gateway-config/gateway-config.service';
import {PaymentComponent} from './routes/payment/payment.component';
import {PaymentResultComponent} from './routes/payment-result/payment-result.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

registerLocaleData(localeDE);

export function initializeApp(store: Store, configService: ConfigService, gatewayConfigService: GatewayConfigService): () => Promise<any> {
  return (): Promise<any> => {
    return configService.getConfig().pipe(
      mergeMap((cfg) => store.dispatch(new SetConfig(cfg))),
      mergeMap(() => store.dispatch(new SetTheme(
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ?
          Themes.dark :
          Themes.light
        ))
      ),
      mergeMap((state) => gatewayConfigService.getConfig(state.AppState.config.gateway)),
      mergeMap((cfg) => store.dispatch(new SetGatewayConfig(cfg))),
      mergeMap(() => store.dispatch(new UserRestore()))
    ).toPromise();
  };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(http));
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TransactionDetailsComponent,
    LoginComponent,
    LoadingComponent,
    HeaderComponent,
    FooterComponent,
    TransactionListComponent,
    CardInfoComponent,
    CardControlsComponent,
    PartnersComponent,
    TransactionListItemComponent,
    AccountComponent,
    AccountCardComponent,
    AccountChangePinComponent,
    TransactionDetailsOverviewComponent,
    TransactionDetailsReceiptComponent,
    TransactionFilterComponent,
    DatepickerComponent,
    ImprintComponent,
    RegisterComponent,
    LoginRegisterWrapperComponent,
    MaintenanceComponent,
    PaymentComponent,
    PaymentResultComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxsModule.forRoot(
      [
        AppState
      ],
      {
        developmentMode: !environment.production
      }
    ),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Store, ConfigService, GatewayConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
