import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {PaycardResponseModel} from './paycard.response.model';
import {TransactionsListResponse} from './transactionsList.response';
import {TransactionDetailResponseModel} from './transactionDetail.response.model';
import {TransactionFilterModel} from '../../models/transactionFilter.model';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend
  ) {
  }

  getPaycardDetails(): Observable<PaycardResponseModel> {
    return this.http.get<PaycardResponseModel>(`/v2/card`);
  }

  getTransactions(page: number, size: number, filter: TransactionFilterModel): Observable<TransactionsListResponse> {
    const fromDateObj = filter ? filter.fromDate : null;
    const toDateObj = filter ? filter.toDate : null;
    let fromDate;
    let toDate;

    if (fromDateObj) {
      fromDateObj.setHours(0, 0, 0, 0);
      fromDate = Math.round((fromDateObj.getTime() - (fromDateObj.getTimezoneOffset() * 60 * 1000)) / 1000);
    }

    if (toDateObj) {
      toDateObj.setHours(23, 59, 59, 999);
      toDate = Math.round((toDateObj.getTime() - (toDateObj.getTimezoneOffset() * 60 * 1000)) / 1000);
    }

    return this.http.get<TransactionsListResponse>(`/v2/card/transaction`, {
      params: {
        page: page.toString(),
        size: size.toString(),
        pos: filter.pos,
        fromDate: fromDate ? fromDate : '',
        toDate: toDate ? toDate : ''
      }
    });
  }

  getTransactionDetail(transactionId: string): Observable<TransactionDetailResponseModel> {
    return this.http.get<TransactionDetailResponseModel>(`/v2/card/transaction/${transactionId}`);
  }

  // this request is not intercepted by auth or api interceptor, so all information has to been given here
  changePin(cardId: string, oldPin: string, pin: string, api: string): Observable<void> {
    const httpClient = new HttpClient(this.httpBackend);
    const authString = btoa(unescape(encodeURIComponent((`${cardId}:${oldPin}`))));

    return httpClient.put<void>(
      `${api}/v2/card`,
      {pin},
      {
        headers: {
          Authorization: `Basic ${authString}`
        }
      }
    );
  }
}
