import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {AppStateConfigModel} from '../../state/appState.model';
import {HttpBackend, HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private httpClient: HttpClient;

  constructor( handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  getConfig(): Observable<AppStateConfigModel> {
    return this.httpClient.get<AppStateConfigModel>(`/assets/config.json?v=${new Date().getTime()}`);
  }
}
