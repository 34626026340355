<header>
  <div class="left" (click)="onGoHome()">
    <span class="app-title">
      {{ 'header.app-title' | translate }}
    </span>
    <span class="app-title-small">
      <i class="fas fa-home"></i>
    </span>
  </div>
  <div class="middle" *ngIf="card$ | async as card">
    <ng-container *ngIf="routerState$ | async as routerState">
      <ng-container *ngIf="routerState.url === '/' && isScrolled$ | async as isScrolled">
        <ng-container *ngIf="currentLanguage$ | async as language">
        <span>
          {{ card.balance/100 | currency:card.currency:'symbol':'':language.key }}
        </span>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <nav class="nav">
    <div class="language" *ngIf="config$ | async as config">
      <ng-container *ngFor="let aL of config.availableLanguages; let i = index">
        <a
          [routerLink]=""
          class="link language"
          [class.active]="(currentLanguage$ | async).key === aL.key"
          (click)="onSetLanguage(aL)"
        >
          {{ aL.description }}
        </a>
        <span *ngIf="i !== config.availableLanguages.length - 1">
          /
        </span>
      </ng-container>
    </div>
    <div class="controls">
      <a
        (click)="onSwitchTheme()"
        class="link theme"
        [routerLink]=""
      >
        <i class="fas fa-tint"></i>
      </a>
    </div>
  </nav>
</header>
