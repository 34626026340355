export class RouteHome {
  static readonly type = '[Routing] Go Home';
}

export class RouteLogin {
  static readonly type = '[Routing] Go Login';
  constructor(public cardId?: string) {}
}

export class RouteRegister {
  static readonly type = '[Routing] Go Register';
  constructor(public cardId: string) {}
}

export class RouteAccount {
  static readonly type = '[Routing] Go Account';
}

export class RouteImprint {
  static readonly type = '[Routing] Go Imprint';
  constructor(public anchor: 'imprint' | 'tos' | 'privacy') {}
}

export class RouteTransactionDetails {
  static readonly type = '[Routing] Go Transaction Details';
  constructor(public saleId: string) {}
}

export class RoutePayment {
  static readonly type = '[Routing] Go Payment';
  constructor() {}
}
