<div class="transaction-item" [class.deposit]="transactionListItem.amount > 0">
  <div class="icon-wrap">
    <figure>
      <ng-container *ngIf="transactionListItem.amount > 0; else negative">
        <i class="fas fa-plus"></i>
      </ng-container>
      <ng-template #negative>
        <i class="fas fa-minus"></i>
      </ng-template>
    </figure>
  </div>
  <div class="transaction-details">
    <div class="pos">
      {{
      (transactionListItem.type === TransactionType.Charge || transactionListItem.type === TransactionType.WebCharge || transactionListItem.type === TransactionType.PreCharge) && transactionListItem.amount > 0 ?
        (('transaction-details.transaction.types.' + transactionListItem.type) | translate) : (
          transactionListItem.pos ? transactionListItem.pos : (
            transactionListItem.transactionId ? transactionListItem.transactionId : '-'
          )
        )
      }}
    </div>
    <div class="reference" *ngIf="transactionListItem.reference">
      <small>
        {{ transactionListItem.reference }}
      </small>
    </div>
    <time class="date" [dateTime]="transactionListItem.date">
      <ng-container *ngIf="currentLanguage$ | async as language">
        <small>
          {{ transactionListItem.date | date:'longDate':'+0000':language.key }}
        </small>
      </ng-container>
    </time>
  </div>
  <div class="amount-wrap">
    <ng-container *ngIf="currentLanguage$ | async as language">
        <span class="amount">
          {{ (transactionListItem.amount / 100) | currency:transactionListItem.currency:'symbol':'':language.key }}
        </span>
    </ng-container>
  </div>
</div>
