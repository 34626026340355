import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Store} from '@ngxs/store';
import {UpdatePin} from '../../../state/actions/card.actions';
import {StopLoading} from '../../../state/actions/general.actions';

@Component({
  selector: 'app-account-change-pin',
  templateUrl: './account-change-pin.component.html',
  styleUrls: ['./account-change-pin.component.scss']
})
export class AccountChangePinComponent implements OnInit {
  public pinForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.pinForm = this.fb.group({
        pin: new FormControl(
          '',
          [
            Validators.required
          ]
        ),
        newPin: new FormControl(
          '',
          [
            Validators.required,
            Validators.minLength(4)
          ]
        ),
        newPinRepeat: new FormControl(
          '',
          [
            Validators.required
          ]
        )
      },
      {
        validator: this.checkPasswords
      }
    );
  }

  submitPinChange(): void {
    const {pin, newPin} = this.pinForm.getRawValue();
    this.store.dispatch(new UpdatePin(pin, newPin)).subscribe(
      () => {
      },
      (error) => {
        this.pinForm.reset();
        if (error && error.status === 401) {
          this.pinForm.setErrors({unauthorized: true});
        } else {
          this.pinForm.setErrors({unknown: true});
        }
        this.store.dispatch(new StopLoading());
      }
    );
  }

  private checkPasswords(group: FormGroup): ValidationErrors {
    const newPin = group.get('newPin').value;
    const newPinRepeat = group.get('newPinRepeat').value;

    return newPin === newPinRepeat ? null : {notMatching: true};
  }
}
