<app-transaction-filter class="no-print"></app-transaction-filter>
<ng-container *ngIf="transactionList$ | async as transactionList">
  <hr *ngIf="transactionList.items.length > 0">
  <app-transaction-list-item
    *ngFor="let l of transactionList.items"
    [transactionListItem]="l"
    [class.not-clickable]="!l.saleId || !l.transactionId"
    (click)="l.saleId && l.transactionId && onShowTransactionDetails(l)"
  ></app-transaction-list-item>
  <hr class="no-print">
  <div class="controls no-print">
    <button
      type="button"
      class="btn"
      (click)="loadMore()"
      *ngIf="!transactionList.isLast; else noMoreTransactions"
    >
      {{ 'home.transaction-list.controls.load-more' | translate }}
    </button>
    <ng-template #noMoreTransactions>
          <span>
            {{ 'home.transaction-list.controls.no-more' | translate }}
          </span>
    </ng-template>
  </div>
</ng-container>
