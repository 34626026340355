import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GatewayConfigModel} from './gatewayConfig.model';

@Injectable({
  providedIn: 'root'
})
export class GatewayConfigService {
  private httpClient: HttpClient;

  constructor( handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  getConfig(baseURL?: string): Observable<GatewayConfigModel> {
    return this.httpClient.get<GatewayConfigModel>(`${baseURL}/v2/config`);
  }
}
