import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../../state/appState';
import {Observable, ObservableInput} from 'rxjs';
import {AppStateConfigModel} from '../../../state/appState.model';
import {LanguageModel} from '../../models/language.model';
import {SetLanguage, SwitchTheme} from '../../../state/actions/general.actions';
import {CardModel} from '../../models/card.model';
import {ActivatedRoute, Router, RouterStateSnapshot} from '@angular/router';
import {RouterState} from '@ngxs/router-plugin';
import {RouteHome} from '../../../state/actions/routing.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Select(AppState.config$) config$: Observable<AppStateConfigModel>;
  @Select(AppState.currentLanguage$) currentLanguage$: Observable<LanguageModel>;
  @Select(AppState.currentCard$) card$: Observable<CardModel>;
  @Select(AppState.isScrolled$) isScrolled$: Observable<boolean>;

  @Select(RouterState.state) routerState$: Observable<RouterStateSnapshot>;


  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) { }

  onSetLanguage(language: LanguageModel): void {
    this.store.dispatch(new SetLanguage(language));
  }

  onSwitchTheme(): void {
    this.store.dispatch(new SwitchTheme());
  }

  onGoHome(): void {
    this.store.dispatch(new RouteHome());
  }

}
