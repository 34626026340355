import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../state/appState';
import {TransactionDetailModel} from '../../shared/models/transactionDetail.model';
import {ActivatedRoute} from '@angular/router';
import {GetTransactionDetails} from '../../state/actions/card.actions';
import {StartLoading, StopLoading} from '../../state/actions/general.actions';
import {catchError, mergeMap} from 'rxjs/operators';
import {LanguageModel} from '../../shared/models/language.model';
import {RouteHome} from '../../state/actions/routing.actions';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit, OnDestroy {
  @Select(AppState.transactionDetails$) transactionDetails$: Observable<TransactionDetailModel>;
  @Select(AppState.currentLanguage$) currentLanguage$: Observable<LanguageModel>;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(p => {
        this.store.dispatch(new StartLoading()).pipe(
          mergeMap(() => this.store.dispatch(new GetTransactionDetails(p.transactionId))),
          mergeMap(() => this.store.dispatch(new StopLoading())),
          catchError((error) => {
            console.log(error);
            return this.store.dispatch(new RouteHome()).pipe(
              mergeMap(() => this.store.dispatch(new StopLoading()))
            );
          })
        ).subscribe();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onGoHome(): void {
    this.store.dispatch(new RouteHome());
  }
}
