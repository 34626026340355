<div class="hint">
  <h1>
    Wartungsmodus
  </h1>
  <p>
    Wir aktualisieren gerade diese Anwendung und sind gleich wieder für dich da.
  </p>
</div>
<div class="hint">
  <h1>
    Maintenance
  </h1>
  <p>
    We are updating the application and will be back in a few minutes.
  </p>
</div>
