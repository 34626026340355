import {Component, OnDestroy} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../state/appState';
import {Observable, Subscription} from 'rxjs';
import {CardModel} from '../../shared/models/card.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {
  @Select(AppState.currentCard$) currentCard$: Observable<CardModel>;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
