<footer *ngIf="config$ | async as config">
  <div class="imprint-links">
    <a (click)="onShowImprint()" class="link" *ngIf="config.showImprint">
      {{ 'footer.imprint' | translate }}
    </a>
    <a (click)="onShowTos()" class="link" *ngIf="config.showTos">
      {{ 'footer.tos' | translate }}
    </a>
    <a (click)="onShowPrivacy()" class="link" *ngIf="config.showPrivacy">
      {{ 'footer.privacy' | translate }}
    </a>
  </div>
</footer>
