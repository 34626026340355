import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TransactionListItemModel} from '../../../shared/models/transactionListItem.model';
import {Select, Store} from '@ngxs/store';
import {RouteTransactionDetails} from '../../../state/actions/routing.actions';
import {AppState} from '../../../state/appState';
import {Observable, Subscription} from 'rxjs';
import {TransactionListPaginationModel} from '../../../state/appState.model';
import {StartLoading, StopLoading} from '../../../state/actions/general.actions';
import {catchError, mergeMap} from 'rxjs/operators';
import {GetMoreTransactionListItems} from '../../../state/actions/card.actions';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent implements OnInit, OnDestroy {
  @Select(AppState.transactionList$) transactionList$: Observable<TransactionListPaginationModel>;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(new StartLoading());

    this.subscriptions.push(
      this.transactionList$.subscribe(t => {
        this.store.dispatch(new StopLoading());
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onShowTransactionDetails(t: TransactionListItemModel): void {
    this.store.dispatch(new RouteTransactionDetails(t.transactionId));
  }

  loadMore(): void {
    console.log('load more...');
    this.store.dispatch(new StartLoading()).pipe(
      mergeMap(() => this.store.dispatch(new GetMoreTransactionListItems())),
      catchError(() => this.store.dispatch(new StopLoading()))
    ).subscribe();
  }
}
