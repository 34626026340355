<div class="content-wrap" *ngIf="config$ | async as config">
  <div id="imprint" class="row" *ngIf="config.showImprint">
    <div class="title">
      <h2>
        {{ 'imprint-tos-privacy.imprint.title' | translate }}
      </h2>
    </div>
    <div class="content">
      {{ 'imprint-tos-privacy.imprint.content' | translate }}
    </div>
  </div>

  <hr>

  <div id="tos" class="row" *ngIf="config.showTos">
    <div class="title">
      <h2>
        {{ 'imprint-tos-privacy.tos.title' | translate }}
      </h2>
    </div>
    <div class="content">
      {{ 'imprint-tos-privacy.tos.content' | translate }}
    </div>
  </div>

  <hr>

  <div id="privacy" class="row" *ngIf="config.showPrivacy">
    <div class="title">
      <h2>
        {{ 'imprint-tos-privacy.privacy.title' | translate }}
      </h2>
    </div>
    <div class="content">
      {{ 'imprint-tos-privacy.privacy.content' | translate }}
    </div>
  </div>

  &nbsp;
</div>
