<div class="row">
  <div class="content">
    <span>
      {{ card.cardId }}
    </span>
  </div>
  <div class="caption">
    <small>
      {{ 'account.card.card-id' | translate }}
    </small>
  </div>
</div>
<div class="row" *ngIf="card.accessId">
  <div class="content">
    <span>
      {{ card.accessId }}
    </span>
  </div>
  <div class="caption">
    <small>
      {{ 'account.card.access-id' | translate }}
    </small>
  </div>
</div>
<div class="row">
  <div class="content">
      <span *ngIf="currentLanguage$ | async as language">
        {{ card.balance / 100 | currency:card.currency:'symbol':'':language.key }}
      </span>
  </div>
  <div class="caption">
    <small>
      {{ 'account.card.balance' | translate }}
    </small>
  </div>
</div>
<div class="row" *ngIf="card.monthlyTopupRemaining != null">
  <div class="content">
    <span *ngIf="currentLanguage$ | async as language">
      {{ card.monthlyTopupRemaining / 100 | currency:card.currency:'symbol':'':language.key }}
    </span>
  </div>
  <div class="caption">
    <small>
      {{ 'account.card.monthlyTopupRemaining' | translate }}
    </small>
  </div>
</div>
<div class="row" *ngIf="card.maxCardLimit != null">
  <div class="content">
    <span *ngIf="currentLanguage$ | async as language">
      {{ card.maxCardLimit / 100 | currency:card.currency:'symbol':'':language.key }}
    </span>
  </div>
  <div class="caption">
    <small>
      {{ 'account.card.maxCardLimit' | translate }}
    </small>
  </div>
</div>
<div class="row" *ngIf="card.paymentFrom">
  <div class="content">
    <span>
      {{ card.paymentFrom | date }}
    </span>
  </div>
  <div class="caption">
    <small>
      {{ 'account.card.paymentFrom' | translate }}
    </small>
  </div>
</div>
<div class="row" *ngIf="card.paymentTo">
  <div class="content">
    <span>
      {{ card.paymentTo | date }}
    </span>
  </div>
  <div class="caption">
    <small>
      {{ 'account.card.paymentTo' | translate }}
    </small>
  </div>
</div>
<div class="row" *ngIf="card.chargeFrom">
  <div class="content">
    <span>
      {{ card.chargeFrom | date }}
    </span>
  </div>
  <div class="caption">
    <small>
      {{ 'account.card.chargeFrom' | translate }}
    </small>
  </div>
</div>
<div class="row" *ngIf="card.chargeTo">
  <div class="content">
    <span>
      {{ card.chargeTo | date }}
    </span>
  </div>
  <div class="caption">
    <small>
      {{ 'account.card.chargeTo' | translate }}
    </small>
  </div>
</div>

<div class="row">
  <div class="content">
    <span>
      {{ card.edition }}
    </span>
  </div>
  <div class="caption">
    <small>
      {{ 'account.card.edition' | translate }}
    </small>
  </div>
</div>
