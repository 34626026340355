import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../state/appState';
import {Observable, Subscription} from 'rxjs';
import {CardModel} from '../../shared/models/card.model';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';
import {RouteHome, RouteLogin} from '../../state/actions/routing.actions';
import {ActivatedRoute} from '@angular/router';
import {StartLoading, StopLoading} from '../../state/actions/general.actions';
import {SetupService} from '../../shared/services/setup/setup.service';
import {CardStatusEnum} from '../../shared/enum/cardStatus.enum';
import {SetupErrorCodeEnum} from '../../shared/services/setup/setupErrorCode.enum';
import {Login} from '../../state/actions/card.actions';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @Select(AppState.currentCard$) currentCard$: Observable<CardModel>;
  private subs: Subscription[] = [];

  public registerForm: FormGroup;
  public validCard = false;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private setupService: SetupService
  ) {
    this.store.dispatch(new StartLoading());
  }

  ngOnInit(): void {
    this.store.dispatch(new StartLoading());
    this.subs.push(
      this.activatedRoute.params.subscribe(p => {
        if (p.cardId) {
          this.initForm(p.cardId);
          this.store.dispatch(new StopLoading());
        } else {
          this.store.dispatch(new RouteLogin());
        }
      })
    );
    this.subs.push(
      this.currentCard$.pipe(distinctUntilChanged()).subscribe((p) => {
        if (p != null) {
          this.store.dispatch(new RouteHome());
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  initForm(cardId: string): void {
    this.registerForm = this.fb.group({
        card: new FormControl({value: cardId, disabled: true}, Validators.required),
        pin: new FormControl('', [
          Validators.required,
          Validators.minLength(4)
        ]),
        pinRepeat: new FormControl('')
      },
      {
        validator: this.checkPasswords
      });

    this.setupService.searchCard(cardId).subscribe(
      (cardResponse) => {
        switch (cardResponse.status) {
          case CardStatusEnum.INIT:
            break;
          default:
            return this.store.dispatch(new RouteLogin(cardId));
        }
      },
      (error) => {
        return this.store.dispatch(new RouteLogin(cardId));
      }
    );
  }

  onSubmit(): void {
    const { card, pin } = this.registerForm.getRawValue();

    this.setupService.setInitialPin(card, pin).subscribe(
      () => {
        this.store.dispatch(new Login(card, pin));
      },
      () => {
        this.registerForm.setErrors({unknownError: true});
        this.store.dispatch(new StopLoading());
      }
    );
  }

  onResetForm(): void {
    this.store.dispatch(new RouteLogin(''));
  }

  private checkPasswords(group: FormGroup): ValidationErrors {
    const newPin = group.get('pin').value;
    const newPinRepeat = group.get('pinRepeat').value;

    return newPin === newPinRepeat ? null : {notMatching: true};
  }
}
