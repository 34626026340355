<ng-container *ngIf="config$ | async as config">
  <ng-container *ngIf="gatewayConfig$ | async as gatewayConfig">
    <button
      *ngIf="gatewayConfig.payment.enabled && gatewayConfig.webcharge.enabled && config.payment && config.payment.enabled"
      class="btn icon highlight"
      type="button"
      (click)="onGoPayment()"
    >
      <span>
        <i class="fas fa-coins"></i>
      </span>
      <span>
        {{ "home.controls.payment" | translate }}
      </span>
    </button>

    <button
      class="btn icon"
      type="button"
      (click)="onGoAccount()"
    >
      <span>
        <i class="fas fa-user"></i>
      </span>
        <span>
        {{ "home.controls.account" | translate }}
      </span>
    </button>

    <button
      class="btn icon"
      type="button"
      (click)="onLogout()"
    >
      <span>
        <i class="fas fa-sign-out-alt"></i>
      </span>
        <span>
        {{ "home.controls.logout" | translate }}
      </span>
    </button>

  </ng-container>
</ng-container>
