<ng-container *ngIf="!!transaction.printoutPreview; else noPrintout">
  <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap" rel="stylesheet">
  <div class="row title">
    <h3>
      {{ 'transaction-details.printout' | translate }}
    </h3>
    <button
      class="btn print no-print"
      type="button"
      [title]="'home.transaction-list.filter.placeholder.print' | translate"
      (click)="onPrint()"
    >
      <i class="fas fa-print"></i>
    </button>
  </div>
  <div class="row printout-wrap">
    <div class="printout">
      {{ transaction.printoutPreview }}
    </div>
  </div>
</ng-container>
<ng-template #noPrintout>
  <div class="row title">
    <h3>
      {{ 'transaction-details.no-printout' | translate }}
    </h3>
  </div>
</ng-template>
