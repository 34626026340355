import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './routes/home/home.component';
import {LoginComponent} from './routes/login/login.component';
import {AuthenticatedGuard} from './shared/guards/authenticated.guard';
import {TransactionDetailsComponent} from './routes/transaction-details/transaction-details.component';
import {AccountComponent} from './routes/account/account.component';
import {ImprintComponent} from './routes/imprint/imprint.component';
import {RegisterComponent} from './routes/register/register.component';
import {PaymentComponent} from './routes/payment/payment.component';
import {PaymentResultComponent} from './routes/payment-result/payment-result.component';
import {PaymentEnabledGuard} from './shared/guards/payment-enabled.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [AuthenticatedGuard, PaymentEnabledGuard]
  },
  {
    path: 'payment/:result',
    component: PaymentResultComponent,
    canActivate: [AuthenticatedGuard, PaymentEnabledGuard]
  },
  {
    path: 'transaction/:transactionId',
    component: TransactionDetailsComponent,
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login/:cardId',
    component: LoginComponent
  },
  {
    path: 'register/:cardId',
    component: RegisterComponent
  },
  {
    path: 'imprint',
    component: ImprintComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
