import {Component, Input, OnInit} from '@angular/core';
import {TransactionDetailModel} from '../../../shared/models/transactionDetail.model';

@Component({
  selector: 'app-transaction-details-receipt',
  templateUrl: './transaction-details-receipt.component.html',
  styleUrls: ['./transaction-details-receipt.component.scss']
})
export class TransactionDetailsReceiptComponent {
  @Input() transaction: TransactionDetailModel;

  constructor() { }

  onPrint(): void {
    window.print();
  }
}
