<ng-container *ngIf="currentCard$ | async as currentCard">
  <div class="content-wrap">

    <section class="card">
      <app-card-info
        [card]="currentCard"
      ></app-card-info>
      <app-card-controls> </app-card-controls>
    </section>

    <hr class="no-print">

    <section class="partners">
      <app-partners [issuerImage]="currentCard?.issuerImage"></app-partners>
    </section>

    <hr class="no-print">

    <section class="transactions">
      <ng-container>
        <app-transaction-list
        ></app-transaction-list>
      </ng-container>
    </section>
  </div>

</ng-container>
