import {Component, Input, OnInit} from '@angular/core';
import {CardModel} from '../../../shared/models/card.model';
import {Select} from '@ngxs/store';
import {AppState} from '../../../state/appState';
import {Observable} from 'rxjs';
import {LanguageModel} from '../../../shared/models/language.model';

@Component({
  selector: 'app-account-card',
  templateUrl: './account-card.component.html',
  styleUrls: ['./account-card.component.scss']
})
export class AccountCardComponent {
  @Input() card: CardModel;

  @Select(AppState.currentLanguage$) currentLanguage$: Observable<LanguageModel>;

  constructor() { }
}
