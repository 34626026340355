<app-login-register-wrapper>
  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="login-form" *ngIf="registerForm">
    <img
      class="emblem"
      src="/assets/img/img-login.png"
      alt="city-emblem"
    >
    <div class="input-row">
      <input
        type="text"
        formControlName="card"
        class="input"
        name="card-number"
        id="card-number"
        [placeholder]="'login.form.placeholder.card' | translate"
      >
    </div>
    <div class="input-row">
      <input
        type="password"
        formControlName="pin"
        class="input"
        [class.error]="
          registerForm.get('pin').errors && registerForm.get('pin').errors.minlength ||
          registerForm.errors && registerForm.errors.notMatching
        "
        [placeholder]="'login.form.placeholder.new-pin' | translate"
      >
    </div>
    <div class="input-row with-caption">
      <input
        type="password"
        formControlName="pinRepeat"
        class="input"
        [class.error]="registerForm.errors"
        [placeholder]="'login.form.placeholder.repeat-new-pin' | translate"
      >
      <small (click)="onResetForm()">
        {{ 'login.form.placeholder.not-your-card' | translate }}
      </small>
    </div>

    <div class="error-description">
      <div class="caption" *ngIf="registerForm">
        <ng-container *ngIf="registerForm.get('pin').errors && registerForm.get('pin').errors.minlength; else pinValid">
          <small>
            {{  'login.form.errors.minlength' | translate }}
          </small>
        </ng-container>
        <ng-template #pinValid>
          <small *ngIf="registerForm.errors">
            {{  'login.form.errors.' + (registerForm.errors | keyvalue)[0].key | translate }}
          </small>
        </ng-template>
      </div>
    </div>

    <div class="input-row">
      <button
        type="submit"
        class="btn"
        [disabled]="!registerForm.valid"
      >
          <span>
            {{ 'login.form.buttons.register' | translate }}
          </span>
      </button>
    </div>
  </form>
</app-login-register-wrapper>
