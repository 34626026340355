import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Logout} from '../../../state/actions/card.actions';
import {RouteAccount, RoutePayment} from '../../../state/actions/routing.actions';
import {AppState} from '../../../state/appState';
import {Observable} from 'rxjs';
import {GatewayConfigModel} from '../../../shared/services/gateway-config/gatewayConfig.model';
import {AppStateConfigModel} from '../../../state/appState.model';

@Component({
  selector: 'app-card-controls',
  templateUrl: './card-controls.component.html',
  styleUrls: ['./card-controls.component.scss']
})
export class CardControlsComponent {
  @Select(AppState.gatewayConfig$) gatewayConfig$: Observable<GatewayConfigModel>;
  @Select(AppState.config$) config$: Observable<AppStateConfigModel>;

  constructor(private store: Store) {
  }

  onLogout(): void {
    this.store.dispatch(new Logout());
  }

  onGoAccount(): void {
    this.store.dispatch(new RouteAccount());
  }

  onGoPayment(): void {
    this.store.dispatch(new RoutePayment());
  }
}
