import {AppStateConfigModel} from '../appState.model';
import {LanguageModel} from '../../shared/models/language.model';
import {Themes} from '../../shared/enum/themes.enum';
import {GatewayConfigModel} from '../../shared/services/gateway-config/gatewayConfig.model';

export class StartLoading { static readonly type = '[AppState] Set Loading: True'; }
export class StopLoading { static readonly type = '[AppState] Set Loading: False'; }

export class SetLanguage {
  static readonly type = '[AppState] Switch Language';
  constructor(public language: LanguageModel) {}
}

export class SetConfig {
  static readonly type = '[AppState] Set Config';
  constructor(public config: AppStateConfigModel) {}
}

export class SetGatewayConfig {
  static readonly type = '[AppState] Set Gateway Config';
  constructor(public config: GatewayConfigModel) {}
}

export class SetAccentColor {
  static readonly type = '[AppState] Set Accent Color';
  constructor(public bgColor: string, public fontColor: string) {}
}

export class SwitchTheme {
  static readonly type = '[AppState] Switch Theme';
}

export class SetTheme {
  static readonly type = '[AppState] Set Theme';
  constructor(public theme: Themes) {}
}

export class SetScrolledBody {
  static readonly type = '[AppState] Set Body Scroll';
  constructor(public isScrolled: boolean) {}
}
