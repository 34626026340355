import {Component, Input} from '@angular/core';
import {TransactionDetailModel} from '../../../shared/models/transactionDetail.model';
import {LanguageModel} from '../../../shared/models/language.model';
import {TransactionType} from '../../../shared/enum/transactionType.enum';

@Component({
  selector: 'app-transaction-details-overview',
  templateUrl: './transaction-details-overview.component.html',
  styleUrls: ['./transaction-details-overview.component.scss']
})
export class TransactionDetailsOverviewComponent {
  @Input() transaction: TransactionDetailModel;
  @Input() language: LanguageModel;

  public TransactionType = TransactionType;

  constructor() { }
}
