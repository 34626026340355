<ng-container>
  <div class="row">
    <div class="content">
      <span>
        {{ (transaction.amount / 100) | currency:transaction.currency:'symbol':'':language.key }}
      </span>
    </div>
    <div class="caption">
      <small>
        {{ 'transaction-details.transaction.amount' | translate }}
      </small>
    </div>
  </div>
  <div class="row" *ngIf="transaction.type !== TransactionType.Charge && transaction.type !== TransactionType.WebCharge && transaction.type !== TransactionType.PreCharge">
    <div class="content">
    <span>
      {{ transaction.pos }}
    </span>
    </div>
    <div class="caption">
      <small>
        {{ 'transaction-details.transaction.pos' | translate }}
      </small>
    </div>
  </div>
  <div class="row" *ngIf="transaction.reference">
    <div class="content">
    <span>
      {{ transaction.reference }}
    </span>
    </div>
    <div class="caption">
      <small>
        {{ 'transaction-details.transaction.reference' | translate }}
      </small>
    </div>
  </div>
  <div class="row">
    <div class="content">
    <span>
      {{ transaction.date | date:'longDate':'+0000':language.key }}
    </span>
    </div>
    <div class="caption">
      <small>
        {{ 'transaction-details.transaction.date' | translate }}
      </small>
    </div>
  </div>
  <div class="row">
    <div class="content">
    <span>
      {{ ('transaction-details.transaction.types.' + transaction.type) | translate }}
    </span>
    </div>
    <div class="caption">
      <small>
        {{ 'transaction-details.transaction.type' | translate }}
      </small>
    </div>
  </div>
  <div class="row">
    <div class="content">
    <span>
      {{ transaction.transactionId }}
    </span>
    </div>
    <div class="caption">
      <small>
        {{ 'transaction-details.transaction.transaction-id' | translate }}
      </small>
    </div>
  </div>
  <div class="row">
    <div class="content">
    <span>
      {{ transaction.saleId }}
    </span>
    </div>
    <div class="caption">
      <small>
        {{ 'transaction-details.transaction.sale-id' | translate }}
      </small>
    </div>
  </div>
</ng-container>
