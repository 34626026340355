<div class="content-wrap">
  <div class="row no-print">
    <h2 class="title">
      {{ 'transaction-details.title' | translate }}
    </h2>
  </div>
  <div
    *ngIf="transactionDetails$ | async as transaction"
    class="row"
  >
    <app-transaction-details-overview
      class="no-print"
      [transaction]="transactionDetails$ | async"
      [language]="currentLanguage$ | async"
    ></app-transaction-details-overview>
    <app-transaction-details-receipt
      [transaction]="transactionDetails$ | async"
    ></app-transaction-details-receipt>
  </div>
  &nbsp;
</div>
