<div class="datepicker-wrap">
  <input
    #datepicker
    [placeholder]="placeholder"
    [disabled]="disabled"
    class="input"
    type="date"
  >
  <button
    type="button"
    class="btn clear"
    (click)="clearDate()"
    [disabled]="!currentValue || disabled"
    [title]="'home.transaction-list.filter.placeholder.delete-date' | translate"
  >
    <i class="fas fa-trash-alt"></i>
  </button>
</div>
