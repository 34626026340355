import { Component, OnInit } from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../state/appState';
import {Observable} from 'rxjs';
import {CardModel} from '../../shared/models/card.model';
import {StopLoading} from '../../state/actions/general.actions';
import {RouteHome} from '../../state/actions/routing.actions';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  @Select(AppState.currentCard$) currentCard$: Observable<CardModel>;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(new StopLoading());
  }

  onGoHome(): void {
    this.store.dispatch(new RouteHome());
  }
}
