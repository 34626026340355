import { Injectable } from '@angular/core';
import {Themes} from '../enum/themes.enum';

@Injectable({
  providedIn: 'root'
})
export class ThemingService {

  constructor() { }

  setAccentColor(bgColorHEX: string, fontColorHEX: string): void {
    document.documentElement.style.setProperty('--accent-color', bgColorHEX);
    document.documentElement.style.setProperty('--accent-font-color', fontColorHEX);
  }

  setTheme(theme: Themes): void {
    document.getElementById('body').setAttribute('data-theme', theme);
  }
}
