import {TransactionFilterModel} from '../../shared/models/transactionFilter.model';

export class UserRestore {
  static readonly type = '[Card] Restore User';
}

export class Login {
  static readonly type = '[Card] Login';
  constructor(public card: string, public pin: string) {}
}

export class Logout {
  static readonly type = '[Card] Logout';
}

export class GetCardDetails {
  static readonly type = '[Card] Get Card Details';
}

export class GetTransactionList {
  static readonly type = '[Card] Get Transaction List';
}

export class GetMoreTransactionListItems {
  static readonly type = '[Card] Get More Transaction List Items';
}

export class GetTransactionDetails {
  static readonly type = '[Card] Get Transaction Details';
  constructor(public transactionId: string) {}
}

export class SetTransactionFilter {
  static readonly type = '[Card] Set Transaction Filter';
  constructor(public filter: TransactionFilterModel) {}
}

export class UpdatePin {
  static readonly type = '[Card] Update PIN';
  constructor(public oldPin: string, public newPin: string) {}
}
