import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AppState} from './state/appState';
import {Observable, Subscription} from 'rxjs';
import {SetScrolledBody} from './state/actions/general.actions';
import {Debounce} from 'lodash-decorators';
import {AppStateConfigModel} from './state/appState.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(AppState.loading$) public loading$: Observable<boolean>;
  @Select(AppState.config$) config$: Observable<AppStateConfigModel>;

  public loading = true;

  private subscriptions: Subscription[] = [];

  constructor(private store: Store) {
  }

  @Debounce(10)
  manageScroll(event: any): void {
    const scrollTop = event.target.scrollTop;
    if (scrollTop > 80) {
      this.store.dispatch(new SetScrolledBody(true));
    } else {
      this.store.dispatch(new SetScrolledBody(false));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.loading$.subscribe(l => {
        setTimeout(() => {
          this.loading = l;
        });
      })
    );
  }

  onActivate(e, scrollContainer): void {
    scrollContainer.scrollTop = 0;
  }
}
