import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../state/appState';
import {AppStateConfigModel} from '../../state/appState.model';
import {StopLoading} from '../../state/actions/general.actions';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements AfterViewInit, OnDestroy {
  @Select(AppState.config$) config$: Observable<AppStateConfigModel>;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private store: Store
  ) { }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        try {
          document.querySelector('#' + params.fragment).scrollIntoView();
        } catch (e) { }
        this.store.dispatch(new StopLoading());
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
